import React, { Component } from 'react';
import './LoadingSpinner.css'; 

export class LoadingSpinner extends Component {
  render() {
    // You can use them as regular CSS styles
    return (
      <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="lds-roller">
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
    );
  }
}