import { useEffect, useState } from "react";
import moment from "moment";

import { LoadingSpinner } from "./LoadingSpinner";
import './App.css';

const queryString = require("query-string");
const betmateApiBaseUrl = process.env.REACT_APP_BETMATE_API_URL;
const betmateEnv = process.env.REACT_APP_BETMATE_ENV;

export default function App() {

  // TrustPayments environment info
  const formPostUrl = "https://payments.securetrading.net/process/payments/choice";
  const stProfile = "default";
  const stDefaultProfile = "st_paymentcardonly";
  const currencyIso3a = "GBP";
  const stVersion = "2";

  // read the querystring
  const queryStringObject = queryString.parse(location.search);
  const { accessToken, amount } = queryStringObject;

  const [siteSecurityTimestamp, setSiteSecurityTimestamp] = useState(null);
  const [settleDueDate, setSettleDueDate] = useState(null);

  const [isDevSite, setIsDevSite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [betmateUserId, setBetmateUserId] = useState(null);
  const [betmateUsername, setBetmateUsername] = useState(null);
  const [siteSecurityHash, setSiteSecurityHash] = useState(null);
  const [siteReference, setSiteReference] = useState(null);

  useEffect(async () => {
    // date/times have to be created once and 
    // stored in state as to not re-create each render
    const fixedSiteSecurityTimestamp = moment.utc().format("YYYY-MM-DD HH:mm:ss");
    const fixedSettleDueDate = moment.utc().format("YYYY-MM-DD");
    setSiteSecurityTimestamp(fixedSiteSecurityTimestamp);
    setSettleDueDate(fixedSettleDueDate);

    if (betmateEnv === "development" || betmateEnv === "local") {
      setIsDevSite(true);
    }

    try {
      const getUserDepositFlowDetailsEndpoint = betmateApiBaseUrl + "/GetUserDepositFlowDetails";
      const getUserDepositFlowDetailsRequestInit = {
        crossDomain: true,
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + accessToken,
        },
      };

      var getUserDepositFlowDetailsResponse = await fetch(getUserDepositFlowDetailsEndpoint, getUserDepositFlowDetailsRequestInit);
      var getUserDepositFlowDetailsTypedResponse = await getUserDepositFlowDetailsResponse.json();

      var userId = null;
      var username = null;
      var forename = null;
      var lastname = null;
      var postCode = null;
      var trustPaymentsSiteReference = null;

      if (getUserDepositFlowDetailsTypedResponse && getUserDepositFlowDetailsTypedResponse.success && getUserDepositFlowDetailsTypedResponse.data) {
        userId = getUserDepositFlowDetailsTypedResponse.data.userId;
        setBetmateUserId(userId);

        username = getUserDepositFlowDetailsTypedResponse.data.username;
        setBetmateUsername(username);

        forename = getUserDepositFlowDetailsTypedResponse.data.forename;
        setBillingFirstName(forename);

        lastname = getUserDepositFlowDetailsTypedResponse.data.lastname;
        setBillingLastName(lastname);

        postCode = getUserDepositFlowDetailsTypedResponse.data.postCode;
        setBillingPostcode(postCode);

        trustPaymentsSiteReference = getUserDepositFlowDetailsTypedResponse.data.trustPaymentsSiteReference;
        setSiteReference(trustPaymentsSiteReference);

      } else {
        setSiteSecurityHash(null);
        setIsLoading(false);
        setHasError(true);
        return;
      }

      let depositSiteSecurityHasherPostBody = {
        currencyIso3a,
        amount,
        siteReference: trustPaymentsSiteReference,
        settleStatus,
        settleDueDate: fixedSettleDueDate,
        authMethod,
        stRequiredFields,
        stVersion,
        stProfile,
        ruleIdentifier,
        stDefaultProfile,
        successfulUrlRedirect,
        successfulUrlNotification,
        declinedUrlNotification,
        stExtraUrlNotifyFields,
        credentialsOnFile,
        betmateUserId: userId,
        siteSecurityTimestamp: fixedSiteSecurityTimestamp,
      };

      const depositSiteSecurityHasherRequestInit = {
        crossDomain: true,
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(depositSiteSecurityHasherPostBody)
      };

      var depositSiteSecurityHasherEndpoint = betmateApiBaseUrl + "/DepositSiteSecurityHasher";
      var depositSiteSecurityHasherResponse = await fetch(depositSiteSecurityHasherEndpoint, depositSiteSecurityHasherRequestInit);
      var typedDepositSiteSecurityHasherResponse = await depositSiteSecurityHasherResponse.json();

      if (typedDepositSiteSecurityHasherResponse.success && typedDepositSiteSecurityHasherResponse?.data) {
        setSiteSecurityHash(`h${typedDepositSiteSecurityHasherResponse?.data}`);

        setTimeout(() => {
          document.getElementById("submitButton").click();
          setIsLoading(false);
          setHasError(false);
        }, 500);
      } else {
        setSiteSecurityHash(null);
        setIsLoading(false);
        setHasError(true);
        return;
      }
    } catch (e) {
      console.log(e);
      setSiteSecurityHash(null);
      setIsLoading(false);
      setHasError(true);
    }
  }, []);

  const [billingFirstName, setBillingFirstName] = useState("");
  const [billingLastName, setBillingLastName] = useState("");
  const [billingPostcode, setBillingPostcode] = useState("");


  const authMethod = "FINAL";
  const settleStatus = "0";
  const ruleIdentifier = "STR-6" + "STR-8" + "STR-9";
  const successfulUrlRedirect = "https://deposit-success.betmate.app";
  const successfulUrlNotification = `${betmateApiBaseUrl}/DepositSuccess`;
  const declinedUrlNotification = `${betmateApiBaseUrl}/DepositFailure`;
  const credentialsOnFile = 0;
  const stExtraUrlNotifyFields = "betmateuserid" + "mainamount";
  const stRequiredFields = "billingfirstname" + "billinglastname" + "billingpostcode";

  return (
    <div className="container">
      {isDevSite && (
        <div style={{margin: 10, textTransform:'uppercase', fontFamily:'Verdana, Arial, Helvetica, sans-serif', fontWeight:'bolder', color: '#393839'}} >
          {betmateEnv}
        </div>
      )}
      {isLoading && <LoadingSpinner />}
      {hasError && (
        <div>
          Something went wrong, please try again.
          <br /><br />
          If this keeps happening, please contact support@betmate.app
        </div>
      )}
      <form method="POST" action={formPostUrl}>
        <input type="hidden" name="currencyiso3a" value={currencyIso3a} />
        <input type="hidden" name="mainamount" value={amount} />
        <input type="hidden" name="sitereference" value={siteReference} />
        <input type="hidden" name="settlestatus" value={settleStatus} />
        <input type="hidden" name="settleduedate" value={settleDueDate} />
        <input type="hidden" name="authmethod" value={authMethod} />
        <input type="hidden" name="strequiredfields" value="billingfirstname" />
        <input type="hidden" name="strequiredfields" value="billinglastname" />
        <input type="hidden" name="strequiredfields" value="billingpostcode" />
        <input type="hidden" name="version" value={stVersion} />
        <input type="hidden" name="stprofile" value={stProfile} />
        <input type="hidden" name="ruleidentifier" value="STR-6" />
        <input type="hidden" name="ruleidentifier" value="STR-8" />
        <input type="hidden" name="ruleidentifier" value="STR-9" />
        <input type="hidden" name="stdefaultprofile" value={stDefaultProfile} />
        <input type="hidden" name="successfulurlredirect" value={successfulUrlRedirect} />
        <input type="hidden" name="successfulurlnotification" value={successfulUrlNotification} />
        <input type="hidden" name="declinedurlnotification" value={declinedUrlNotification} />
        <input type="hidden" name="credentialsonfile" value={credentialsOnFile} />
        <input type="hidden" name="stextraurlnotifyfields" value="betmateuserid" />
        <input type="hidden" name="stextraurlnotifyfields" value="mainamount" />
        <input type="hidden" name="betmateuserid" value={betmateUserId} />
        <input type="hidden" name="sitesecuritytimestamp" value={siteSecurityTimestamp} />
        <input type="hidden" name="sitesecurity" value={siteSecurityHash} />

        <input type="hidden" name="billingcontactdetailsoverride" value="1" />
        <input type="hidden" name="customercontactdetailsoverride" value="1" />

        <input type="hidden" name="orderreference" value={betmateUsername} />
        <input type="hidden" name="billingfirstname" value={billingFirstName} />
        <input type="hidden" name="billinglastname" value={billingLastName} />
        <input type="hidden" name="billingpostcode" value={billingPostcode} />

        <input id="submitButton" type="submit" value="Pay" style={{ display: 'none' }} />
      </form>
    </div>
  );
}
